// firebase.ts

import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCzv81HhGiWjkj-yyCvu_P-mjlXk1_8wcs",

  authDomain: "d5000004990.firebaseapp.com",

  databaseURL: "https://d5000004990-default-rtdb.firebaseio.com",

  projectId: "d5000004990",

  storageBucket: "d5000004990.appspot.com",

  messagingSenderId: "178315570807",

  appId: "1:178315570807:web:402b33304c137d02fe6072",
};

const guardantFirebaseConfig = {
  apiKey: "AIzaSyB95zlEH3tCQrmN-w-duLcX3wWGwyPcyxM",

  authDomain: "o5000005208dev.firebaseapp.com",

  databaseURL: "https://o5000005208dev-default-rtdb.firebaseio.com",

  projectId: "o5000005208dev",

  storageBucket: "o5000005208dev.appspot.com",

  messagingSenderId: "84065127825",

  appId: "1:84065127825:web:ffdd444dd112055b3e5e30",
};

const ucinFirebaseConfig = {
  apiKey: "AIzaSyCWLqswv8JC4bTFlMLr3sznmQtRd_ojy0o",

  authDomain: "o5000005205.firebaseapp.com",

  databaseURL: "https://o5000005205-default-rtdb.firebaseio.com",

  projectId: "o5000005205",

  storageBucket: "o5000005205.appspot.com",

  messagingSenderId: "698666773333",

  appId: "1:698666773333:web:df8161a7103705d7649ddc",
};

const configs: Record<string, any> = {
  guardant: {
    url: "https://cust-guardant-docknow.azurewebsites.net",

    config: guardantFirebaseConfig,
  },

  dev: {
    url: "https://ony-docknow-service.azurewebsites.net",

    config: firebaseConfig,
  },

  ucin: {
    url: "https://ase-ucin-docknow-service-prod-eastus.azurewebsites.net",

    config: ucinFirebaseConfig,
  },
};

const customer = process.env.REACT_APP_CUSTOMER ?? "dev";

const app = initializeApp(configs[customer].config ?? firebaseConfig);

const auth = getAuth(app);

const serviceUrl =
  configs[customer].url ?? "https://ony-docknow-service.azurewebsites.net";

export { app, auth, serviceUrl };
